import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { AngularServicesContext } from 'react-app';
import NvRouter from 'shared/components/nv-router';
import Details from 'learning_journeys/components/details';
import useSemanticMemo from 'shared/hooks/use-semantic-memo';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { fetchJourney } from 'redux/actions/learning-journeys';
import { getInstitutionData } from 'redux/actions/institutions';
import { fetchCurrentUserEnrollments } from 'redux/actions/users';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { Ref as BrandingHeaderRef } from 'shared/components/branding-header';
import UserManagement, {
  UserManagementRef,
} from 'learning_journeys/components/user-management';
import NavigationHeader, {
  HEADER_HEIGHT,
} from 'learning_journeys/components/navigation-header';

type HomeContextType = {
  setScrollStatusTrigger: (trigger: number) => void,
};

export const HomeContext = React.createContext<HomeContextType>(null);

const Home = () => {
  const headerRef = React.useRef<BrandingHeaderRef>();
  const { $state } = React.useContext(AngularServicesContext);
  const [scrollHeaderTrigger, setScrollHeaderTrigger] = React.useState<number>(0);
  const [scrollStatusTrigger, setScrollStatusTrigger] = React.useState<number>(0);

  React.useEffect(() => {
    const { element: headerElement } = headerRef.current || {};

    const {
      offsetTop: headerOffsetTop = 0,
      offsetHeight: headerOffsetHeight = 0,
    } = headerElement || {};

    setScrollHeaderTrigger(headerOffsetTop + headerOffsetHeight);
  }, []);

  const detailsStyles = css`
    z-index: 0;
    position: relative;
  `;

  const interceptedSetScrollStatusTrigger = (value) => setScrollStatusTrigger(value - HEADER_HEIGHT);

  const { catalogId } = $state.params;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoCatalogId = useSemanticMemo(() => catalogId, []);

  return (
    <HomeContext.Provider
      value={{
        setScrollStatusTrigger: interceptedSetScrollStatusTrigger,
      }}
    >
      <div className='scroll-container'>
        <Details
          isViewMode
          css={detailsStyles}
          headerRef={headerRef}
          catalogId={catalogId ?? memoCatalogId}
        />
        <NavigationHeader
          sticky
          scrollHeaderTrigger={scrollHeaderTrigger}
          scrollStatusTrigger={scrollStatusTrigger}
        />
      </div>
    </HomeContext.Provider>
  );
};

const UserManagementWithRedirection = () => {
  const {
    $state,
    CurrentPermissionsManager,
  } = React.useContext(AngularServicesContext);
  const userManagementRedirectRef = React.useRef<Function>();
  const userManagementRef = React.useRef<UserManagementRef>();
  const { isLoading } = React.useContext(LearningJourneyContext);
  const renderUserManagement = !isLoading;
  const stateChangeStartListenerRef = React.useRef((event, toState) => {
    if (toState.parent === 'learning-journey-user-management') {
      userManagementRedirectRef.current(toState.name, event);
    }
  });

  const userManagementRedirect = (stateName, event?) => {
    let stateNameToRedirectTo;

    const basicCheck = CurrentPermissionsManager.isInstructor()
      || CurrentPermissionsManager.isLearnerRegistrationRole()
      || CurrentPermissionsManager.isConfigAndRegistrationRole();

    switch (stateName) {
      case 'learning-journey-user-management-learners':
        if (!basicCheck) {
          stateNameToRedirectTo = 'learning-journey-user-management-admins';
        }
        break;
      case 'learning-journey-user-management-admins':
        if (!CurrentPermissionsManager.hasFullCourseAdminPermissions()) {
          stateNameToRedirectTo = 'learning-journey-user-management-learners';
        }
        break;
      case 'learning-journey-user-management-search':
        if (!basicCheck) {
          stateNameToRedirectTo = 'learning-journey-user-management-admins';
        }
        break;
      case 'learning-journey-user-management-mentors':
        if (!basicCheck) {
          stateNameToRedirectTo = 'learning-journey-user-management-admins';
        }
        break;
      default:
        throw new Error('Unhandled state name in journey user management');
    }

    if (stateNameToRedirectTo) {
      event?.preventDefault();
      $state.go(stateNameToRedirectTo, $state.params);
    }
  };

  userManagementRedirectRef.current = userManagementRedirect;

  // Hook that takes care of the user management redirections between
  // AngularJS inner routes such as 'learning-journey-user-management-learners',
  // 'learning-journey-user-management-admins' and
  // 'learning-journey-user-management-search' and to journey home in case user
  // doesn't have journey admin permissions.
  React.useEffect(() => {
    if (renderUserManagement) {
      if (CurrentPermissionsManager.hasCourseAdminPermissions()) {
        userManagementRedirectRef.current($state.current.name);
      } else {
        $state.go('learning-journey-home');
      }

      userManagementRef.current.$scopeRef.current.$on(
        '$stateChangeStart',
        stateChangeStartListenerRef.current,
      );
    }
  }, [$state, renderUserManagement, CurrentPermissionsManager]);

  return (
    <React.Fragment>
      <NavigationHeader />
      {renderUserManagement && (
        <UserManagement ref={userManagementRef} />
      )}
    </React.Fragment>
  );
};

const contentRoutes = [
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/user-management',
    component: UserManagementWithRedirection,
  },
];

type LearningJourneyContextType = {
  isLoading: boolean,
  loadJourney: Function,
};

export const LearningJourneyContext = React.createContext<LearningJourneyContextType>(null);

const LearningJourney = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const { currentInstitutionId } = useSelector((state) => state.app);

  const {
    $state,
    $scope,
    InstitutionsManager,
    CurrentCourseManager,
  } = React.useContext(AngularServicesContext);

  const { catalogId: paramsCatalogId } = $state.params;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoCatalogId = useSemanticMemo(() => paramsCatalogId, []);

  const catalogId = memoCatalogId;

  const routerBasename = `/#!/journeys/${catalogId}`;

  const loadJourney = React.useCallback(() => wrapThunkAction(
    dispatch(fetchJourney({
      catalogId,
      // Passing true since we want to set current course identifiers in redux
      shouldSetCourse: true,
    })),
  ).then((action: any) => {
    const angularCourse = new $scope.CourseModel(action.payload.journey);

    angularCourse.institution = InstitutionsManager.institution;

    CurrentCourseManager.setCourse(angularCourse);
    $scope.CurrentUserManager.addCourse(angularCourse);

    return action;
  }).catch((action) => {
    if (action.payload === 404 || action.payload === 401) {
      $state.go('root');

      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        message: t.LEARNING_JOURNEYS.ACCESS_DENIED(),
      }));
    }

    // Throwing same action since we are only intercepting the promise.
    throw action;
  }), [
    $state,
    dispatch,
    catalogId,
    $scope.CourseModel,
    CurrentCourseManager,
    $scope.CurrentUserManager,
    InstitutionsManager.institution,
  ]);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      // Getting up to date enrollments each time we load the current journey
      // to ensure completion of actual journey and inner courses are up to
      // date.
      wrapThunkAction(dispatch(fetchCurrentUserEnrollments())),
      // Getting full current institution because currently it's the only way to
      // get the institution profile settings and they're needed for course
      // cards.
      wrapThunkAction(dispatch(
        getInstitutionData({
          institutionId: currentInstitutionId,
        }),
      )),
      loadJourney(),
    ]).then(() => setIsLoading(false)).catch(() => {});
  }, [dispatch, loadJourney, currentInstitutionId, CurrentCourseManager]);

  const styles = css`
    display: flex;
    height: 100vh;
    flex-direction: column;

    .scroll-container {
      flex: 1;
      overflow-y: auto;
      position: relative;
    }
  `;

  return (
    <LearningJourneyContext.Provider
      value={{
        isLoading,
        loadJourney,
      }}
    >
      <div css={styles}>
        <NvRouter
          routes={contentRoutes}
          basename={routerBasename}
        />
      </div>
    </LearningJourneyContext.Provider>
  );
};


export default LearningJourney;
