import {
  DEFAULT_LANGUAGE,
  LANGUAGE_MAPPING
} from 'froala/helpers/nv-froala-constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCourse } from 'redux/selectors/course';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCurrentUser } from 'redux/selectors/users';
import { franc } from 'franc';
import { AUTOMATED_TRANSLATION_LANGUAGES } from 'automated_translation/components/language-picker';

const ISO_CODES = AUTOMATED_TRANSLATION_LANGUAGES.reduce((langs, curr) => {
  langs[curr.francLangCode] = curr.isoCode;
  return langs;
}, {});
const ALLOWED_LANGUAGES = AUTOMATED_TRANSLATION_LANGUAGES.map(
  lang => lang.francLangCode
);

const predictLanguage = (text: string) => {
  const language = franc(text, {
    only: ALLOWED_LANGUAGES
  });
  return language;
};

type UseAutoTranslateProps = {
  text?: string;
};

const useAutoTranslate = ({ text }: UseAutoTranslateProps) => {
  const [isAutoTranslateEnabled, setIsAutoTranslateEnabled] = useState<boolean>(
    false
  );
  const [sourceLanguage, setSourceLanguage] = useState<string>(null);
  const [targetLanguage, setTargetLanguage] = useState<string>(null);
  const [isSameLanguage, setIsSameLanguage] = useState<boolean>(false);

  const {
    automatedTranslationEnabled: isInstitutionAutoTranslationEnabled
  } = useSelector(getCurrentInstitution);
  const {
    automatedTranslationEnabled: isCourseAutoTranslationEnabled
  } = useSelector(getCurrentCourse);
  const {
    platformLanguage,
    translationPreferenceLanguage: autoTranslateLanguage
  } = useSelector(getCurrentUser);

  // The original value returned for the overwritten keys does not work,
  // so using the values the endpoint requires.
  const LANG_MAP = {
    ...LANGUAGE_MAPPING,
    pt_PT: 'pt-pt',
    pt_BR: 'pt',
    zh_CN: 'zh-hans',
    zh_TW: 'zh-hant'
  };

  useEffect(() => {
    setIsAutoTranslateEnabled(
      isInstitutionAutoTranslationEnabled && isCourseAutoTranslationEnabled
    );

    const getPlatformLanguage = platformLanguage
      ? LANG_MAP[platformLanguage]
      : DEFAULT_LANGUAGE;
    const translationLanguage = autoTranslateLanguage || getPlatformLanguage;
    setTargetLanguage(translationLanguage);
  }, []);

  useEffect(() => {
    if (text?.trim().length) {
      const prediction = predictLanguage(text);
      console.log({ text, prediction });
      const detectedLanguage = ISO_CODES[prediction];
      setSourceLanguage(detectedLanguage);
      setIsSameLanguage(detectedLanguage === targetLanguage);
    }
  }, [text, targetLanguage]);

  return {
    isAutoTranslateEnabled,
    sourceLanguage,
    targetLanguage,
    isSameLanguage
  };
};

export default useAutoTranslate;
