import moment from 'moment';
import { useAngularPreventLecturePageNavigation } from '../hooks/use-prevent-lecture-page-navigation';

/* @ngInject */
export default function SubmissionsDiscoverEditFormModalController(
  $uibModalInstance,
  $window,
  $scope,
  $stateParams,
  $timeout,
  _,

  StateManager,
  ConfirmationOverlays,
  CurrentUserManager,
  CurrentCourseManager,
  ExercisesManager,
  validationConstants,

  vmResolves,
) {
  const vm = this;
  let dismissedByUser = false;

  vm.CurrentUserManager = CurrentUserManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.lectureComponent = vmResolves.lectureComponent;
  vm.criteriaDraft = vmResolves.lectureComponent.criteriaDraft;
  vm.validationConstants = validationConstants;
  vm.sortOptions = vm.lectureComponent.constructor.sortOptions;
  vm.isContentManagementCollection = vm.CurrentCourseManager.course.isContentManagementCollection;
  vm.isLinked = vm.lectureComponent?.lecturePage?.isLinked;

  ExercisesManager.getAllExercisesForCourse($stateParams.catalogId).then(exercises => {
    vm.exercisesList = exercises;
    if (vm.lectureComponent.requiredExercise) {
      vm.criteriaDraft.exercise = _.clone(vm.lectureComponent.requiredExercise);
    } else {
      $timeout(() => {
        vm.editSubmissionsDiscoveryForm.$setValidity('selectedTopic', false);
      });
    }
  });

  vm.disableButton = () => {
    if (vm.isContentManagementCollection) {
      if (vm.lectureComponent.id) {
        return vm.editSubmissionsDiscoveryForm.submissionsCount.$invalid || vm.editSubmissionsDiscoveryForm.$pristine;
      }
      return vm.editSubmissionsDiscoveryForm.submissionsCount.$invalid;
    }
    return vm.editSubmissionsDiscoveryForm.$invalid || vm.editSubmissionsDiscoveryForm.$pristine;
  };

  vm.selectExercise = (exercise) => {
    vm.criteriaDraft.exerciseId = exercise.id;
    vm.criteriaDraft.exercise = exercise;

    vm.editSubmissionsDiscoveryForm.$setValidity('selectedTopic', true);
    vm.editSubmissionsDiscoveryForm.$setDirty();
  };

  vm.showReleaseDateWarning = () => {
    if (!vm.criteriaDraft.exercise) {
      return false;
    }
    const exerciseDeadline = moment(vm.criteriaDraft.exercise.deadline);
    const lecturePageRelease = vm.lectureComponent?.lecturePage?.releaseDate
      ? moment(vm.lectureComponent.lecturePage.releaseDate)
      : moment(vm.criteriaDraft.exercise.releaseDate);
    return vm.criteriaDraft.exercise?.hardDeadline
      && exerciseDeadline > lecturePageRelease;
  };

  vm.filterExerciseOptions = () => {
    const exercisesList = _.clone(vm.exercisesList);

    if (!_.isEmpty(vm.exercisesList)
      && vm.lectureComponent.requiredExercise
      && !_.findWhere(exercisesList, { id: vm.lectureComponent.requiredExercise.id })
    ) {
      exercisesList.push(vm.lectureComponent.requiredExercise);
    }

    return exercisesList;
  };

  vm.save = () => {
    dismissedByUser = true;
    $uibModalInstance.close();
  };

  function hasUnsavedChanges() {
    return vm.editSubmissionsDiscoveryForm?.$dirty;
  }

  const checkUnsavedChanges = () => hasUnsavedChanges();

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    'FORM.NAVIGATE_AWAY.CLOSE_WINDOW',
    undefined,
    undefined,
    () => {
      vm.deregisterModalClosing?.();

      $uibModalInstance.dismiss();
    });

  vm.deregisterModalClosing = $scope.$on('modal.closing', ($event) => {
    if (hasUnsavedChanges() && !dismissedByUser) {
      $event.preventDefault();
      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        dismissedByUser = true;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    angular.element($window).off('beforeunload');
    vm.deregisterStateChangeStart?.();
  });
}
