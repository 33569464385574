import React from 'react';
import moment from 'moment';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import { AngularServicesContext } from 'react-app';
import { animate, FrameAnimation } from 'shared/utils';
import StickyHeader from 'shared/components/sticky-header';
import ClickableContainer from 'components/clickable-container';
import { notDesktop } from 'styles/global_defaults/media-queries';
import { patchCourse as patchJourney } from 'redux/actions/courses';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { gray2, gray6, primary } from 'styles/global_defaults/colors';
import LearningStatus from 'learning_journeys/components/learning-status';
import useAngularStateManager from 'shared/hooks/use-angular-state-manager';
import {
  doubleSpacing,
  tripleSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import NvDropdown, {
  NvDropdownOption,
  NvDropdownButtonStyle,
} from 'shared/components/inputs/nv-dropdown';
import {
  createWelcomeEmailDropdownItem,
} from 'learning_journeys/components/welcome-email-dropdown-item';
import CredlyBadgeSettings from 'institutions/components/credly-badge-settings';
import { Institution } from 'redux/schemas/models/institution';
import { config } from '../../../config/pendo.config.json';

export const HEADER_HEIGHT = tripleSpacing;

const dashboardStates = [
  'dashboard',
  'mentor-dashboard',
  'learning-journeys',
  'institution-dashboard',
  'course-admin-dashboard',
  'course-supervisor-dashboard',
];

const reverseFindIndex = (array, eachHandler) => {
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i >= 0; i--) {
    if (eachHandler(array[i])) {
      return i;
    }
  }

  return -1;
};

type Props = {
  sticky?: boolean,
  className?: string,
  scrollHeaderTrigger?: number,
  scrollStatusTrigger?: number,
};

const NavigationHeader = (props: Props) => {
  const {
    className,
    sticky = false,
    scrollHeaderTrigger = 1,
    scrollStatusTrigger = 1,
  } = props;

  const {
    $scope,
    $state,
    $rootScope,
    RailsRoutes,
    CurrentPermissionsManager,
  } = React.useContext(AngularServicesContext);

  const dispatch = useAppDispatch();
  const { catalogId } = $state.params;
  const leftRef = React.useRef<HTMLDivElement>();
  const rightRef = React.useRef<HTMLDivElement>();
  const headerRef = React.useRef<HTMLDivElement>();
  const learningStatusRef = React.useRef<HTMLDivElement>();
  const [maxTitleWidth, setMaxTitleWidth] = React.useState(0);
  const currentInstitution: Institution = useSelector(getCurrentInstitution);
  const currentJourney = useSelector((state) => state.models.courses[catalogId]);
  const currentInstitutionId = useSelector((state) => state.app.currentInstitutionId);
  const isOrgAdmin = CurrentPermissionsManager.hasOrgAdminPermissions()
    || CurrentPermissionsManager.hasCourseManagerPermissions();

  const {
    previousParamsEntered,
    previousStatesEntered,
  } = useAngularStateManager();

  const lastDashboardStateIndex = reverseFindIndex(previousStatesEntered, (state) => {
    if (dashboardStates.includes(state.name)) {
      return true;
    }

    return false;
  });

  const shouldGoToDefaultDashboard = lastDashboardStateIndex === -1;

  const lastDashboardState = shouldGoToDefaultDashboard
    ? undefined
    : previousStatesEntered[lastDashboardStateIndex];

  const lastDashboardStateParams = shouldGoToDefaultDashboard
    ? undefined
    : previousParamsEntered[lastDashboardStateIndex];

  const calculateMaxWidth = React.useCallback(() => {
    const leftWidth = leftRef.current.offsetWidth;
    const rightWidth = rightRef.current.offsetWidth;
    const headerWidth = headerRef.current.offsetWidth;

    const greatestSideElementWidth = Math.max(leftWidth, rightWidth);

    const maxWidth = ((headerWidth / 2) - greatestSideElementWidth) * 2;

    setMaxTitleWidth(maxWidth >= 0 ? maxWidth : 0);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', calculateMaxWidth);

    return () => window.removeEventListener('resize', calculateMaxWidth);
  }, [calculateMaxWidth]);

  const updateJourneyLanguage = (contentLanguage) => dispatch(patchJourney({
    catalogId,
    contentLanguage,
    id: currentJourney.id,
    institutionId: currentInstitutionId,
    currentLanguage: currentJourney.contentLanguage,
  }));

  // Logic to handle the credly badge configuration if the institution has the credly integration
  const hasCredlyIntegration = currentInstitution?.isCredlyEnabled ?? false;
  const handleCredlyBadgeRef = React.useRef<Function>();

  // Function to build the option items for the menu
  function getOptionItems() {
    const pendo = config.pendo.journeyAnalytics;
    const updatedOptionItems = [];

    const optionsHasItem = (id) => updatedOptionItems.find(x => x.id === id);

    if (currentJourney) {
      if (
        (CurrentPermissionsManager.hasCourseManagerPermissions()
        || CurrentPermissionsManager.isConfigAndRegistrationRole())
        && !optionsHasItem('edit-basics')
      ) {
        updatedOptionItems.push({
          id: 'edit-basics',
          type: 'text',
          text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.EDIT_BASICS(),
          callback: () => $state.go('learning-journey-basics', {
            catalogId,
            institutionId: currentInstitutionId,
          }),
          dataQa: `${pendo.editBasics}_cog`,
        });
      }

      if (
        (CurrentPermissionsManager.hasCourseManagerPermissions()
          || CurrentPermissionsManager.isCourseBuilder())
        && !optionsHasItem('edit-journey')
      ) {
        updatedOptionItems.push({
          id: 'edit-journey',
          type: 'text',
          text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.EDIT_JOURNEY(),
          callback: () => $state.go('edit-learning-journey', {
            catalogId,
            institutionId: currentInstitutionId,
          }),
          dataQa: `${pendo.editJourney}_cog`,
        });
      }

      if (
        (CurrentPermissionsManager.hasCourseManagerPermissions()
        || CurrentPermissionsManager.isConfigAndRegistrationRole()
        || CurrentPermissionsManager.isCourseBuilder())
        && !optionsHasItem('welcome-email')
      ) {
        updatedOptionItems.push({
          id: 'welcome-email',
          type: 'text',
          text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.JOURNEY_COMMUNICATIONS(),
          callback: () => $state.go('learning-journey-communications', {
            catalogId,
            institutionId: currentInstitutionId,
          }),
          dataQa: pendo.welcomeEmail,
        });
      }

      if (
        (CurrentPermissionsManager.hasCourseManagerPermissions()
        || CurrentPermissionsManager.isConfigAndRegistrationRole()
        || CurrentPermissionsManager.isCourseBuilder())
        && !optionsHasItem('journey-language')
      ) {
        updatedOptionItems.push({
          id: 'journey-language',
          type: 'text',
          text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.JOURNEY_LANGUAGE(),
          dataQa: `${pendo.journeyLanguage}_cog`,
          callback: () => {
            $scope.$uibModal.open({
              templateUrl: 'shared/templates/language-modal.html',
              controller: 'LanguageModalCtrl as vm',
              resolve: {
                currentUser: null,
                context: {
                  isJourney: true,
                  updateJourneyLanguage,
                  currentLanguage: currentJourney.contentLanguage,
                },
              },
            });
          },
        });
      }
      if (
        (CurrentPermissionsManager.hasCourseManagerPermissions()
        || CurrentPermissionsManager.isConfigAndRegistrationRole()
        || CurrentPermissionsManager.isCourseBuilder()
        || CurrentPermissionsManager.isInstructor())
        && !optionsHasItem('completion-email')
      ) {
        updatedOptionItems.push({
          id: 'completion-email',
          type: 'link',
          targetBlank: true,
          text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.COMPLETION_EMAIL(),
          link: RailsRoutes.courseAdminDashboardTabPath(catalogId, 'statements'),
          dataQa: `${pendo.completionEmail}_cog`,
        });
      }

      if (
        (CurrentPermissionsManager.hasCourseManagerPermissions()
        || CurrentPermissionsManager.isConfigAndRegistrationRole()
        || CurrentPermissionsManager.isCourseBuilder())
        && !optionsHasItem('certificate-of-completion')
      ) {
        updatedOptionItems.push({
          id: 'certificate-of-completion',
          type: 'link',
          targetBlank: true,
          link: RailsRoutes.courseAdminDashboardTabPath(catalogId, 'statements'),
          text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.CERTIFICATE_OF_COMPLETION(),
          dataQa: `${pendo.certificateOfCompletion}_cog`,
        });
      }

      if (
        CurrentPermissionsManager.isCourseBuilder() && hasCredlyIntegration && !optionsHasItem('credly-badge')
      ) {
        updatedOptionItems.push({
          id: 'credly-badge',
          dataQa: config.pendo.credly.journeyMenuItem,
          type: 'text',
          text: t.COURSE_ADMIN_MEGA_MENU.CREDLY_BADGE.LABEL(),
          callback: () => handleCredlyBadgeRef.current(),
        });
      }
      if (updatedOptionItems.length > 0) {
        updatedOptionItems.push({ type: 'divider' });
      }
      if (
        (CurrentPermissionsManager.hasCourseManagerPermissions()
        || CurrentPermissionsManager.isLearnerRegistrationRole()
        || CurrentPermissionsManager.hasFullCourseAdminPermissions())
        && !optionsHasItem('user-management')
      ) {
        updatedOptionItems.push({
          id: 'user-management',
          type: 'text',
          text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.USER_MANAGEMENT(),
          callback: () => $state.go('learning-journey-user-management-learners', { catalogId }),
          dataQa: `${pendo.userManagement}_cog`,
        });
      }

      if (CurrentPermissionsManager.hasJourneyAnalyticsAccess()
      ) {
        updatedOptionItems.push({
          type: 'text',
          text: t.LEARNING_JOURNEYS.DASHBOARD.JOURNEY_OPTIONS.ANALYTICS(),
          callback: () => $state.go('learning-journey-analytics', { catalogId, institutionId: currentInstitution.id, from: 'learning-journey-home' }),
          dataQa: `${pendo.analytics}_cog`,
        });
      }
    }

    return updatedOptionItems;
  }

  const optionItems: NvDropdownOption[] = getOptionItems();

  React.useLayoutEffect(() => {
    calculateMaxWidth();
  }, [calculateMaxWidth]);

  const { nameFontColor = '#fff' } = currentJourney || {};
  const brandingColor = sticky ? 'transparent' : currentInstitution.brandColor;

  const styles = css`
    .navigation-header {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      transition: all 0.5s;
      justify-content: center;
      height: ${HEADER_HEIGHT}px;
      border-top: ${quarterSpacing}px solid ${brandingColor};
      background-color: ${sticky ? 'transparent' : '#fff'};
      border-bottom: 1px solid ${sticky ? 'transparent' : gray6};

      ${sticky && css`
        &.scrolled {
          background-color: #fff;
          border-bottom: 1px solid ${gray6};
          border-top: ${quarterSpacing}px solid ${currentInstitution.brandColor};
        }
      `};

      .left, .right {
        top: 0;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        transition: all 0.5s;
        justify-content: center;
      }

      .left {
        left: 0;
        padding-right: ${doubleSpacing}px;
        color: ${sticky ? nameFontColor : '#000'};

        ${sticky && css`
          &.scrolled {
            color: #000;
          }
        `};

        .lhs-menu {
          width: 54px;
          height: 54px;
          display: none;
          align-items: center;
          justify-content: center;

          ${notDesktop(css`
            display: flex;
          `)};
        }

        .back-button {
          align-items: center;

          ${!sticky && css`
            &:hover {
              color: ${primary};
            }
          `}

          .icon {
            display: block;
            margin-left: ${standardSpacing}px;

            ${notDesktop(css`
              display: none;
            `)};
          }
       }
    }

    .right {
      right: 0;
      padding-left: ${doubleSpacing}px;
      color: ${sticky ? nameFontColor : gray2};
      padding-right: ${standardSpacing}px;

      ${sticky && css`
        &.scrolled {
          color: ${gray2};
        }
      `};
    }

    .title {
      min-width: 0;
      transition: opacity 0.5s;
      opacity: ${sticky ? 0 : 1};
      max-width: ${maxTitleWidth}px;

      ${sticky && css`
        &.scrolled {
          opacity: 1;
        }
      `};
    }
  }
`;

  const handleBackClick = () => {
    if (isOrgAdmin) {
      $state.go('learning-journeys', { institutionId: currentInstitutionId });
    } else if (shouldGoToDefaultDashboard) {
      $state.go('root');
    } else {
      $state.go(lastDashboardState.name, lastDashboardStateParams);
    }
  };

  const handleLhsMenuClick = () => $rootScope.$$childHead.MainGridCtrl.toggleLhsMobile();

  const formattedReleaseDate = currentJourney?.releaseDate
    ? moment(currentJourney.releaseDate).format('L')
    : null;

  return (
    <StickyHeader
      sticky={sticky}
      className={className}
      triggers={{
        header: scrollHeaderTrigger,
        status: scrollStatusTrigger,
      }}
    >
      {({
        header: scrolledHeader,
        status: scrolledStatus,
      }) => (
        <div
          css={styles}
          ref={headerRef}
        >
          <div
            className={`navigation-header${scrolledHeader ? ' scrolled' : ''}`}
          >
            <div
              ref={leftRef}
              className={`left${scrolledHeader ? ' scrolled' : ''}`}
            >
              <div className='lhs-menu'>
                <ClickableContainer onClick={handleLhsMenuClick}>
                  <NvIcon icon='menu' size='small' />
                </ClickableContainer>
              </div>
              <ClickableContainer
                className='back-button'
                onClick={handleBackClick}
              >
                <NvIcon size='small' icon='back' className='mr-2' />
                <div className='course-subtitle'>
                  {shouldGoToDefaultDashboard
                    ? t.DASHBOARD.GO_TO_DASHBOARD()
                    : t.DASHBOARD.BACK_TO_DASHBOARD()}
                </div>
              </ClickableContainer>
            </div>
            <div className={`course-title-regular title ellipsis${scrolledHeader ? ' scrolled' : ''}`}>
              {currentJourney?.name ?? ''}
            </div>
            <div
              ref={rightRef}
              className={`right${scrolledHeader ? ' scrolled' : ''}`}
            >
              {!!optionItems.length && (
                <React.Fragment>
                  {formattedReleaseDate && (
                    <div className='release-info mr-2 course-title-xxs'>
                      {t.LEARNING_JOURNEYS.DETAILS[currentJourney?.released ? 'PAST_RELEASE' : 'FUTURE_RELEASE'](formattedReleaseDate)}
                    </div>
                  )}
                  <NvDropdown
                    useEffectFlushFix
                    items={optionItems}
                    iconClass='icon-small icon-settings'
                    buttonStyle={NvDropdownButtonStyle.ICON}
                    toggleDataQa={config.pendo.learningJourneys.settings}
                  />
                </React.Fragment>
              )}
            </div>
          </div>

          {hasCredlyIntegration && (
            <CredlyBadgeSettings
              forwardShowModal={func => {
                handleCredlyBadgeRef.current = func;
              }}
            />
          )}

          <Fade show={scrolledStatus}>
            <LearningStatus
              condensed
              ref={learningStatusRef}
            />
          </Fade>
        </div>
      )}
    </StickyHeader>
  );
};

type FadeProps = {
  show: boolean,
  children: React.ReactElement,
};

const Fade = (props: FadeProps) => {
  const {
    show,
    children,
  } = props;

  const mountedRef = React.useRef(false);
  const showRef = React.useRef<boolean>();
  const elementRef = React.useRef<HTMLElement>();
  const renderElementRef = React.useRef<boolean>();
  const animateElementRef = React.useRef<Function>();
  const currentAnimationRef = React.useRef<FrameAnimation>();
  const [renderElement, setRenderElement] = React.useState(show);
  showRef.current = show;
  renderElementRef.current = renderElement;

  animateElementRef.current = () => {
    const startAnimation = animate(250, (duration, progress) => {
      if (showRef.current) {
        elementRef.current.style.opacity = ((1 / duration) * progress).toString();
      } else {
        elementRef.current.style.opacity = (1 - (1 / duration) * progress).toString();
      }
    });

    currentAnimationRef.current = startAnimation();

    return currentAnimationRef.current.animationPromise;
  };

  React.useLayoutEffect(() => {
    if (mountedRef.current) {
      currentAnimationRef.current?.stopAnimation();

      if (show) {
        if (!renderElementRef.current) {
          setRenderElement(true);
        }
      } else {
        animateElementRef.current().then((result) => {
          if (result.status === 'finished') {
            setRenderElement(false);
          }
        });
      }
    }
  }, [show]);

  React.useLayoutEffect(() => {
    if (mountedRef.current && renderElement) {
      animateElementRef.current();
    }
  }, [renderElement]);

  React.useEffect(() => {
    mountedRef.current = true;
  }, []);

  return renderElement && React.cloneElement(children, {
    ref: elementRef,
  });
};

export default NavigationHeader;
