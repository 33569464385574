import React, { useMemo } from 'react';
import * as yup from 'yup';
import last from 'lodash/last';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  get,
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
  FieldArrayWithId,
} from 'react-hook-form';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import NvIcon from 'shared/components/nv-icon';
import NvFroala from 'froala/components/nv-froala';
import { AngularServicesContext } from 'react-app';
import { PopoversContainerContext } from 'shared/react-utils';
import { gray3, primary, success } from 'styles/global_defaults/colors';
import ClickableContainer from 'components/clickable-container';
import CourseRow from 'learning_journeys/components/course-row';
import { FroalaViewMode } from 'froala/helpers/nv-froala-constants';
import LoadingPlaceholder from 'shared/components/loading-placeholder';
import { boldFontWeight, openSans } from 'styles/global_defaults/fonts';
import LearningStatus from 'learning_journeys/components/learning-status';
import { updateJourneyCollections } from 'redux/actions/learning-journeys';
import useAngularStateManager from 'shared/hooks/use-angular-state-manager';
import { Ref as BrandingHeaderRef } from 'shared/components/branding-header';
import { CollectionCompletionStats, Course, CourseRegistrationType } from 'redux/schemas/models/course';
import OfferedToSection from 'learning_journeys/components/offered-to-section';
import InputCourseSearch from 'learning_journeys/components/input-course-search';
import useJourneyFormUtils from 'learning_journeys/hooks/use-journey-form-utils';
import OfferingsBrandingHeader from 'offerings/components/offering-branding-header';
import {
  openSavingOverlay,
  closeSavingOverlay,
} from 'redux/actions/saving-overlay';
import {
  getJourney,
  getJourneyCollections,
} from 'redux/selectors/learning-journeys';
import { getCoursesArray } from 'redux/selectors/course';
import { getCurrentUserEnrollments } from 'redux/selectors/users';
import { CombinedCourse } from 'redux/schemas';
import {
  halfSpacing,
  doubleSpacing,
  tripleSpacing,
  standardSpacing,
  threeQuartersSpacing,
  journeyIconSize,
} from 'styles/global_defaults/scaffolding';
import {
  LearningJourneyContext,
} from 'learning_journeys/components/learning-journey';
import {
  FormCollectionHeader,
} from 'learning_journeys/components/collection-header';
import useCourseUsageRegistry, {
  CourseUsageRegistryProvider,
  useCourseUsageRegistryContext,
} from 'learning_journeys/hooks/use-course-usage-regisitry';
import {
  CompletionCriteria,
  CompletionCriteriaLabel,
  FormCompletionCriteriaDropdown,
  Value as CompletionCriteriaValue,
  CollectionsCompletionCriteria,
} from 'learning_journeys/components/completion-criteria-dropdown';
import NvNoResults from 'shared/components/nv-no-results-panel';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';

import { VisitedLearningJourneySchema } from 'redux/schemas/app/learning-journey';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import CoursesCarousel from './courses-carousel';
import CollectionSeparator from './divider';
import { config } from '../../../config/pendo.config.json';
import OrderCollectionsLock from './order-collections-lock';
import { CompletedIcon, LockedIcon, Optional } from './status-components';

const MAX_COLLECTION_NAME_LENGTH = 190;

const getEmptyCollection = () => ({
  name: '',
  courses: [],
  description: '',
  completionCriteria: {
    meta: null,
    value: CompletionCriteria.ALL_IN_ORDER,
  },
});

const limitedWidthContainerStyles = css`
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${standardSpacing}px;
  padding-right: ${standardSpacing}px;
`;

const masterContainerStyles = css`
  padding-bottom: 140px;
  padding-top: ${doubleSpacing}px;
`;

const journeyDetailsContext = React.createContext<{
  catalogId: string,
  isViewMode: boolean,
}>(null);

/**
 * Type to prevent ts(2589) error "Type instantiation is excessively deep and
 * possibly infinite."
 */
type InterceptedCourse = Omit<Course, 'collections'>;

type FormCollection = {
  id: number,
  name: string,
  description: string,
  courses: InterceptedCourse[],
  completionCriteria: CompletionCriteriaValue,
};

type FormData = {
  description: string,
  collections: FormCollection[],
  orderLocked: boolean,
  autoEnrollment: boolean,
};

const JourneyContent = () => {
  const dispatch = useAppDispatch();
  const courses = useSelector(getCoursesArray);
  const angularServices = React.useContext(AngularServicesContext);
  const { $scope, $state } = angularServices;
  const { catalogId, isViewMode } = React.useContext(journeyDetailsContext);
  const currentJourney = useSelector((state) => getJourney(state, catalogId));

  const {
    previousStatesEntered,
    previousParamsEntered,
  } = useAngularStateManager();

  const lastStateEntered: any = last(previousStatesEntered);
  const lastParamsEntered = last(previousParamsEntered);

  const journeyCollections = useSelector(
    (state) => getJourneyCollections(state, catalogId),
  );

  const userEnrollment = useSelector((state) => Object.values(state.models.enrollments)
    .find((enrollment: any) => currentJourney.id === enrollment.courseId));

  const isJourneyEmpty = currentJourney.coursesCount === 0;
  const isJourneyComplete = !!userEnrollment?.completionStatus
    || (currentJourney?.completionStats?.required > 0 && currentJourney.completionStats?.collections.every(({ completed, required }) => completed >= required));

  const validationSchemaRef = React.useRef(yup.object().shape({
    collections: yup.array().of(yup.object().shape({
      name: yup.string().max(MAX_COLLECTION_NAME_LENGTH, t.VALIDATION.MAX_LENGTH(`${MAX_COLLECTION_NAME_LENGTH}`)),
      completionCriteria: yup.object().shape({
        value: yup.number().oneOf([
          CompletionCriteria.ALL_IN_ORDER,
          CompletionCriteria.ALL_IN_ANY_ORDER,
          CompletionCriteria.AT_LEAST_X_COURSES,
          CompletionCriteria.OPTIONAL,
        ]),
        meta: yup.lazy((value) => {
          if (value === '') {
            return yup.string().min(1, t.VALIDATION.REQUIRED());
          }

          if (typeof value === 'number') {
            return yup.number().min(1, t.VALIDATION.REQUIRED());
          }

          return yup.mixed();
        }),
      }),
    })),
    orderLocked: yup.boolean(),
    autoEnrollment: yup.boolean(),
  }));

  const getInitialFormData = () => {
    if (currentJourney.collectionIds?.length) {
      return {
        description: currentJourney.description,
        collections: journeyCollections.map((collection) => ({
          id: collection.id,
          key: collection.id,
          name: collection.name,
          description: collection.description,
          courses: collection.courseIds.map((courseId) => courses.find((course) => course.id === courseId)),
          completionCriteria: {
            meta: collection.numRequiredCourses,
            value: collection.completionSettings,
          },
        })),
        orderLocked: currentJourney.completionSettings === CollectionsCompletionCriteria.ALL_IN_ORDER,
        autoEnrollment: currentJourney.autoEnrollment,
      };
    }

    return {
      collections: [getEmptyCollection()],
      description: currentJourney.description,
      orderLocked: currentJourney.completionSettings === CollectionsCompletionCriteria.ALL_IN_ORDER,
      autoEnrollment: currentJourney.autoEnrollment,
    };
  };

  const initialFormData = getInitialFormData();

  const courseUsageRegistry = useCourseUsageRegistry(
    initialFormData.collections,
  );

  const methods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: initialFormData,
    resolver: yupResolver(validationSchemaRef.current),
  });

  const { isDirty, isValid } = methods.formState;
  const { watch } = methods;

  const journeyFormUtils = useJourneyFormUtils(methods.formState);

  const {
    fields: collections,
    swap: swapCollection,
    append: appendToCollections,
    remove: removeFromCollections,
  } = useFieldArray<FormData, 'collections', 'key'>({
    name: 'collections',
    control: methods.control,
  });

  const userCourse = useSelector((state) => state.models.enrollments[currentJourney?.userCourse]);

  React.useEffect(() => {
    if (isJourneyComplete && isViewMode && userCourse) {
      $scope.ConfettiAnimation.showConfetti();
    }
  }, [$scope.ConfettiAnimation, isJourneyComplete, isViewMode, userCourse]);

  const styles = css`
    ${masterContainerStyles};

    .offered-to {
      margin-bottom: ${doubleSpacing}px;
    }

    .separator {
      margin: ${standardSpacing}px auto;
    }

    textarea {
      min-height: 100px;
      max-height: 200px;
    }

    .collections {
      .collection {
        padding-bottom: ${doubleSpacing + journeyIconSize / 2}px;
        .limited-width-collection-container {
          margin-bottom: ${isViewMode ? standardSpacing : 0}px;
        }

        .collection-header {
          margin-top: ${doubleSpacing}px;
          margin-bottom: ${halfSpacing}px;
        }

        .collection-completion-criteria {
          margin-bottom: ${halfSpacing}px;
        }

        .collection-description {
          margin-bottom: ${standardSpacing}px;
        }

        .collection-courses {
          margin-bottom: ${standardSpacing}px;
        }

        .courses-search {
          justify-content: center;

          .autocomplete {
            flex: 1;
            max-width: 400px;
          }
        }
      }
    }

    .journey-footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon-container {
        display: flex;
        align-items: center;
        margin: ${doubleSpacing}px 0 ${standardSpacing}px;

        .separator {
          margin: 0;
        }

        i {
          margin: 0 ${threeQuartersSpacing}px;
          color: ${success};
        }
      }

      .text {
        color: ${gray3};
        text-align: center;
        font-weight: ${boldFontWeight};

        a {
          color: ${primary};
        }
      }
    }

    .new-collection {
      margin-bottom: ${tripleSpacing}px;
    }

    .journey-description {
      padding-top: ${standardSpacing}px;
      padding-bottom: ${tripleSpacing}px;
    }
  `;

  const handleNewCollectionButtonClick = () => appendToCollections(getEmptyCollection());

  const handleFormCancel = () => {
    if (lastStateEntered) {
      $state.go(lastStateEntered.name, lastParamsEntered);
    }
  };

  const handleFormSubmit = (data: FormData) => {
    const {
      description,
      collections: newCollections,
      orderLocked,
      autoEnrollment,
    } = data;

    const doUpdate = () => {
      dispatch(openSavingOverlay());
      methods.reset(data);
      wrapThunkAction(dispatch(updateJourneyCollections({
        catalogId,
        description,
        collections: newCollections.map((collection, index) => ({
          index,
          id: collection.id,
          name: collection.name,
          description: collection.description,
          completionSettings: collection.completionCriteria.value,
          courseIds: collection.courses.map((course) => course.id),
          numRequiredCourses: collection.completionCriteria.meta || null,
        })),
        completionSettings: orderLocked ? CollectionsCompletionCriteria.ALL_IN_ORDER : CollectionsCompletionCriteria.ALL_IN_ANY_ORDER,
        autoEnrollment,
      })))
        .then(() => {
          if ($state.params.isNewJourney) {
            $state.go('learning-journey-home', { catalogId });
          } else if (lastStateEntered) {
            $state.go(lastStateEntered?.name, lastParamsEntered);
          }

          journeyFormUtils.showSuccessAlert(true);
        }, journeyFormUtils.showErrorAlert)
        .finally(() => dispatch(closeSavingOverlay()));
    };

    if (initialFormData.autoEnrollment && !autoEnrollment) {
      dispatch(openConfirmationDialog({
        title: t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.AUTO_ENROLLMENT.WARNING.TITLE(),
        bodyText: t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.AUTO_ENROLLMENT.WARNING.MESSAGE(),
        confirmText: t.FORM.YES_SURE(),
        onConfirm: () => doUpdate(),
      }));
    } else {
      doUpdate();
    }
  };

  // Logic to inform the badges/certificates of the learning journey
  let selectCase: 'both' | 'credly' | 'soa' = 'both';
  let credlyLink = '';
  const credlyBadgeEnabled = currentJourney?.credlyBadgeInfo?.id;

  if (credlyBadgeEnabled) {
    selectCase = 'credly';
    credlyLink = currentJourney?.credlyBadgeInfo?.url;
  }

  if (currentJourney?.soaCertificateEnabled) {
    selectCase = credlyBadgeEnabled ? 'both' : 'soa';
  }

  // Finding the first required / non-optional collection to know if
  // a collection is blocked or not.
  const firstNonOptionalCollectionIndex = (() => {
    let collectionIndex = -1;
    for (let currentCollectionIndex = 0; currentCollectionIndex < collections?.length && collectionIndex === -1; currentCollectionIndex += 1) {
      // Find the latest non optional collection to check its completion
      const collectionCompletionCriteria = watch(`collections.${currentCollectionIndex}.completionCriteria`);
      if (collectionCompletionCriteria.value !== CompletionCriteria.OPTIONAL) {
        collectionIndex = currentCollectionIndex;
      }
    }
    return collectionIndex;
  })();

  return (
    <CourseUsageRegistryProvider {...courseUsageRegistry}>
      {isViewMode && <LearningStatus isStatic />}
      <div css={styles}>
        {(isJourneyEmpty && isViewMode) ? (
          <div css={limitedWidthContainerStyles}>
            <NvNoResults
              action={() => {}}
              noResultsText={t.LEARNING_JOURNEYS.DETAILS.COURSES.EMPTY_RESULTS()}
            />
          </div>
        ) : (
          <FormProvider {...methods}>
            <div css={limitedWidthContainerStyles}>
              {currentJourney.typeOfRegistration === CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS && (
                <OfferedToSection journey={currentJourney} className='offered-to' />
              )}
              {isViewMode ? (
                !!currentJourney.description && (
                  <ResponsivelyEmbeddedAngularHTML
                    className='journey-description'
                    angularServices={angularServices}
                    template={currentJourney.description}
                  />
                )
              ) : (
                <NvFroala
                  withForm
                  name='description'
                  immediateReactModelUpdate
                  preset={FroalaViewMode.NORMAL}
                  className='journey-description'
                  placeholder={t.LEARNING_JOURNEYS.DETAILS.JOURNEY_DESCRIPTION_PLACEHOLDER()}
                />
              )}
            </div>
            <OrderCollectionsLock
              orderName='orderLocked'
              autoEnrollmentName='autoEnrollment'
              visible={!isViewMode}
              style={limitedWidthContainerStyles}
              learnerAlias={currentJourney.learnersName}
              courseAlias={currentJourney.programCoursesName}
            />
            <div className='collections'>
              <CollectionCompletionsStatusProvider>
                {collections.map((collection, index, array) => (
                  <Collection
                    index={index}
                    collections={array}
                    key={collection.id}
                    collection={collection}
                    swapCollection={swapCollection}
                    removeFromCollections={removeFromCollections}
                    collectionCompletionStats={currentJourney.completionStats?.collections.find(({ id }) => id === collection.id)}
                    firstNonOptionalCollectionIndex={firstNonOptionalCollectionIndex}
                  />
                ))}
              </CollectionCompletionsStatusProvider>
            </div>
            {!isViewMode && !!collections.length && (
              <CollectionSeparator className='separator' />
            )}
            {isViewMode ? (
              !isJourneyComplete && !isJourneyEmpty && (
                <div
                  className='journey-footer'
                  css={limitedWidthContainerStyles}
                >
                  <div className='icon-container'>
                    <CollectionSeparator className='separator' />
                    {currentJourney.soaCertificateEnabled || credlyBadgeEnabled
                      ? <NvIcon icon='badge' size='large' />
                      : <NvIcon icon='success' size='large' />}
                    <CollectionSeparator className='separator' />
                  </div>
                  <span className='text'>
                    {currentJourney.soaCertificateEnabled || credlyBadgeEnabled
                      ? t.LEARNING_JOURNEYS.DETAILS.STATEMENT_OF_ACCOMPLISHMENT(
                        credlyLink, selectCase,
                      )
                      : t.LEARNING_JOURNEYS.DETAILS.END_OF_JOURNEY()}
                  </span>
                </div>
              )
            ) : (
              <div css={limitedWidthContainerStyles}>
                <div className='text-center'>
                  <NewCollectionButton
                    className='new-collection'
                    onClick={handleNewCollectionButtonClick}
                  />
                </div>
                <div className='button-bar'>
                  <Button
                    variant='secondary'
                    onClick={handleFormCancel}
                  >
                    {t.FORM.CANCEL()}
                  </Button>
                  <Button
                    variant='primary'
                    disabled={!isValid || !isDirty}
                    onClick={methods.handleSubmit(handleFormSubmit)}
                    pendo-tag-name={config.pendo.learningJourneys.saveJourney}
                  >
                    {t.FORM.SAVE()}
                  </Button>
                </div>
              </div>
            )}
          </FormProvider>
        )}
      </div>
    </CourseUsageRegistryProvider>
  );
};

// eslint-disable-next-line no-spaced-func
export const journeyCollectionContext = React.createContext<{
  getIsCourseLocked: (index: number) => boolean,
}>(null);

type CollectionProps = {
  index: number;
  swapCollection: (indexA: number, indexB: number) => void;
  removeFromCollections: (index?: number | number[]) => void;
  collection: FieldArrayWithId<FormData, 'collections', 'key'>;
  collections: FieldArrayWithId<FormData, 'collections', 'key'>[];
  collectionCompletionStats: CollectionCompletionStats;
  firstNonOptionalCollectionIndex: number;
};

const Collection = (props: CollectionProps) => {
  const {
    index,
    collection,
    collections,
    swapCollection,
    removeFromCollections,
    collectionCompletionStats,
    firstNonOptionalCollectionIndex,
  } = props;

  const canMoveUp = index > 0;
  const isFirstCollection = index === 0;
  const { formState, watch } = useFormContext();
  const { free } = useCourseUsageRegistryContext();
  const canMoveDown = index < collections.length - 1;
  const autoEnrollment = watch('autoEnrollment');
  const collectionName = watch(`collections.${index}.name`);
  const { catalogId, isViewMode } = React.useContext(journeyDetailsContext);
  const angularServices = React.useContext(AngularServicesContext);
  const currentUserEnrollments = useSelector(getCurrentUserEnrollments);
  const collectionCoursesIds = collection.courses.map(course => course.id);
  const completionCriteria = watch(`collections.${index}.completionCriteria`);
  const currentJourney = useSelector((state) => getJourney(state, catalogId));
  // Checking if the user already started the Journey or not
  const userCourse = useSelector((state) => state.models.enrollments[currentJourney?.userCourse]);
  const journeyInfo: VisitedLearningJourneySchema = {
    id: currentJourney?.id,
    catalogId: currentJourney?.catalogId,
    name: currentJourney?.name,
    releaseDate: currentJourney?.releaseDate,
    closeDate: currentJourney?.closeDate,
    headerColor: currentJourney?.headerColor,
    nameFontColor: currentJourney?.nameFontColor,
    headerBackground: currentJourney?.headerBackground,
    completionStats: currentJourney?.completionStats,
  };

  const {
    collectionCompletions,
    setCollectionCompleted,
  } = React.useContext(collectionCompletionsStatusContext);

  const collectionEnrollments = collectionCoursesIds.map(
    courseId => currentUserEnrollments.find(
      enrollment => courseId === enrollment.courseId,
    ),
  );

  const isPrevCollectionCompleted = useMemo(() => {
    if (collectionCompletions?.[index] && completionCriteria.value !== CompletionCriteria.OPTIONAL) {
      for (let currentCollectionIndex = index; currentCollectionIndex > 0; currentCollectionIndex -= 1) {
        const previousCollectionIndex = currentCollectionIndex - 1;
        // Find the latest non optional collection to check its completion
        const previousCollectionCompletionCriteria = watch(`collections.${previousCollectionIndex}.completionCriteria`);
        if (previousCollectionCompletionCriteria.value !== CompletionCriteria.OPTIONAL) {
          return collectionCompletions[previousCollectionIndex]?.completed;
        }
      }
    }
    // If we couldn't find any previous non optional collection, that means all collections
    // before the current one are optional OR this is the first collection of the list (index == 0),
    // returning 'true' in both cases.
    return true;
  }, [collectionCompletions]);

  const isCollectionCompleted = isPrevCollectionCompleted
    && collectionCompletionStats
    && (collectionCompletionStats.completed >= collectionCompletionStats.required)
    && userCourse;

  React.useLayoutEffect(() => {
    setCollectionCompleted(index, isCollectionCompleted, completionCriteria.value === CompletionCriteria.OPTIONAL);
  }, [index, isCollectionCompleted, setCollectionCompleted]);

  const handleDelete = () => {
    collections[index].courses.forEach((course) => {
      free(course.catalogId);
    });

    removeFromCollections(index);
  };

  const handleMoveUp = () => swapCollection(index, index - 1);
  const handleMoveDown = () => swapCollection(index, index + 1);

  const journeyCollectionContextValue = {
    getIsCourseLocked: (courseIndex: number) => {
      const isFirstCourse = courseIndex === 0;
      const prevCourseEnrollment = isFirstCourse ? null : collectionEnrollments[courseIndex - 1];
      const isPrevCourseCompleted = isFirstCourse ? true : prevCourseEnrollment?.completionStatus;
      const isCompletionInOrder = completionCriteria.value === CompletionCriteria.ALL_IN_ORDER;
      const isOptional = completionCriteria.value === CompletionCriteria.OPTIONAL;
      const orderLocked = watch('orderLocked');

      // Case for journey home page
      if (isViewMode) {
        // Case for when previous collection is completed or the journey can be
        // completed in any order.
        if (isPrevCollectionCompleted || !orderLocked) {
          // Case for when collection has completion in order criteria
          if (isCompletionInOrder) {
            return !isFirstCourse && !isPrevCourseCompleted;
          }

          // If collection is not completion in order
          return false;
        }

        // If previous collection is not completed, and the journey order is locked
        // all courses should be locked
        return true;
      }

      // Case for edit mode and is first collection or the first non optional collection
      if (isFirstCollection || firstNonOptionalCollectionIndex === index) {
        // If completion is in order, the only unlocked course should be the
        // first one
        if (isCompletionInOrder) {
          return !isFirstCourse;
        }

        // If completion is not in order, all the courses should be unlocked
        return false;
      }

      // Case for the rest of collections in edit mode, check for the order Lock
      if (!orderLocked) {
        // If 'Must Complete In Order' options is selected, it unblocks the first course
        // Otherwise unblock all the courses.
        if (isCompletionInOrder) {
          return !isFirstCourse;
        }
        return false;
      }

      // Every optional collection has all its courses open
      if (isOptional) {
        return false;
      }

      // Case for edit mode that is not in first collection. Any course whose
      // collection is locked should be locked, so in edit mode the only
      // unlocked collection is the first one.
      return true;
    },
  };

  const getStatusComponent = () => {
    // In case the order is locked, then we show icons depending on its status
    const orderLocked = watch('orderLocked');
    // If its optional, it will always show the title "OPTIONAL"
    if (completionCriteria.value === CompletionCriteria.OPTIONAL) {
      return <Optional />;
    }
    // No matter if its locked or not, when a journey is completed
    // and is in view mode, it shows the completed icon
    if (isCollectionCompleted && isViewMode) {
      return <CompletedIcon />;
    }
    // If it's the first collection and we are in edit mode or is not completed
    // we show a divider without icons
    if (isFirstCollection && (!isCollectionCompleted || !isViewMode)) {
      return null;
    }
    // The order is locked, so we need to check the previous non optional
    // collection.
    if (orderLocked
      && ((!isViewMode
        && firstNonOptionalCollectionIndex > -1
        && firstNonOptionalCollectionIndex !== index)
      || !isPrevCollectionCompleted)) {
      return <LockedIcon isViewMode={isViewMode} />;
    }
    return null;
  };

  return (
    <journeyCollectionContext.Provider value={journeyCollectionContextValue}>
      <div
        className='collection'
        data-qa={config.pendo.learningJourneys.collection}
        data-qa-id={`${config.pendo.learningJourneys.collection}-${index}`}
      >
        <CollectionSeparator className='separator' extraComponent={getStatusComponent()} />
        <div
          css={limitedWidthContainerStyles}
          className='limited-width-collection-container'
        >
          {(isViewMode ? !!collectionName : true) && (
            <FormCollectionHeader
              editable={!isViewMode}
              canMoveUp={canMoveUp}
              onMoveUp={handleMoveUp}
              onDelete={handleDelete}
              canMoveDown={canMoveDown}
              onMoveDown={handleMoveDown}
              className='collection-header'
              defaultValue={collection.name}
              name={`collections.${index}.name`}
            />
          )}
          <div className='text-center'>
            {(isViewMode || autoEnrollment) ? (
              <CompletionCriteriaLabel
                completionCriteria={completionCriteria}
                className='collection-completion-criteria'
                isCollectionCompleted={isCollectionCompleted}
              />
            ) : (
              <FormCompletionCriteriaDropdown
                defaultValue={completionCriteria}
                className='collection-completion-criteria'
                name={`collections.${index}.completionCriteria`}
                inputError={get(
                  formState.errors,
                  `collections.${index}.completionCriteria.meta`,
                )}
              />
            )}
          </div>
          {isViewMode ? (
            !!collection.description && (
              <ResponsivelyEmbeddedAngularHTML
                template={collection.description}
                className='collection-description'
                angularServices={angularServices}
              />
            )
          ) : (
            <NvFroala
              withForm
              immediateReactModelUpdate
              preset={FroalaViewMode.NORMAL}
              className='collection-description'
              name={`collections.${index}.description`}
              placeholder={t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.COLLECTION_DESCRIPTION_PLACEHOLDER()}
            />
          )}
          {!isViewMode && (
            <CollectionCourses
              className='collection-courses'
              autocompleteClassName='autocomplete'
              name={`collections.${index}.courses`}
              inputCourseSearchClassName='courses-search'
            />
          )}
        </div>
        {isViewMode && !!collection.courses.length && (
          <CoursesCarousel
            courses={collection.courses as CombinedCourse[]}
            journeyInfo={journeyInfo}
          />
        )}
      </div>
    </journeyCollectionContext.Provider>
  );
};

type CollectionCoursesProps = {
  name: string,
  className?: string,
  autocompleteClassName?: string,
  inputCourseSearchClassName?: string,
};

const CollectionCourses = (props: CollectionCoursesProps) => {
  const {
    name,
    className,
    autocompleteClassName,
    inputCourseSearchClassName,
  } = props;

  const { getIsCourseLocked } = React.useContext(journeyCollectionContext);

  const {
    free,
    allocate,
    registry,
  } = useCourseUsageRegistryContext();

  const {
    fields: courses,
    swap: swapCourse,
    append: appendToFormCourses,
    remove: removeFromCourses,
  } = useFieldArray<FormData, 'collections.0.courses', 'key'>({
    name: name as 'collections.0.courses',
    keyName: 'key',
  });

  const appendToCourses = (newCourse) => {
    appendToFormCourses(newCourse);
    allocate(newCourse.catalogId);
  };

  return (
    <React.Fragment>
      <div className={className}>
        {courses.map((course, index, array) => {
          const canMoveUp = index > 0;
          const canMoveDown = index < array.length - 1;
          const handleMoveUp = () => swapCourse(index, index - 1);
          const handleMoveDown = () => swapCourse(index, index + 1);

          const handleDelete = () => {
            removeFromCourses(index);
            free(course.catalogId);
          };

          return (
            <CourseRow
              key={course.id}
              canMoveUp={canMoveUp}
              onMoveUp={handleMoveUp}
              onDelete={handleDelete}
              canMoveDown={canMoveDown}
              onMoveDown={handleMoveDown}
              locked={getIsCourseLocked(index)}
              course={course as unknown as Course}
              dataQa={config.pendo.learningJourneys.courseInCollection}
              dataQaId={`${config.pendo.learningJourneys.courseInCollection}-${index}`}
            />
          );
        })}
      </div>
      <InputCourseSearch
        onCourseAdd={appendToCourses}
        deniedCoursesDictionary={registry}
        className={inputCourseSearchClassName}
        autocompleteClassName={autocompleteClassName}
      />
    </React.Fragment>
  );
};

type NewCollectionButtonProps = {
  onClick: () => void,
  className?: string,
};

const NewCollectionButton = (props: NewCollectionButtonProps) => {
  const {
    onClick,
    className,
  } = props;

  const styles = css`
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    display: inline-flex;
    font-family: ${openSans};

    .icon {
      margin-right: ${halfSpacing}px;
    }
  `;

  return (
    <ClickableContainer
      {...props}
      css={styles}
      onClick={onClick}
      pendo-tag-name={config.pendo.learningJourneys.addNewCollection}
      className={`btn btn-link hovered${className ? ` ${className}` : ''}`}
    >
      <NvIcon
        icon='add'
        size='smallest'
      />
      {t.LEARNING_JOURNEYS.DETAILS.COLLECTIONS.NEW_COLLECTION()}
    </ClickableContainer>
  );
};

type CollectionCompletions = {
  [index: number]: {
    completed: boolean,
    optional: boolean,
  }
};
// eslint-disable-next-line no-spaced-func
const collectionCompletionsStatusContext = React.createContext<{
  collectionCompletions: CollectionCompletions,
  setCollectionCompleted: (index: number, completed: boolean, optional: boolean) => void,
}>(null);

type CollectionCompletionStatusProviderProps = {
  children: React.ReactNode,
};

const CollectionCompletionsStatusProvider = (props: CollectionCompletionStatusProviderProps) => {
  const { children } = props;
  const [
    collectionCompletions,
    setCollectionCompletions,
  ] = React.useState<CollectionCompletions>({});

  const setCollectionCompleted = React.useCallback((index: number, completed: boolean, optional: boolean) => {
    setCollectionCompletions((prev) => ({
      ...prev,
      [index]: {
        completed,
        optional,
      },
    }));
  }, []);

  return (
    <collectionCompletionsStatusContext.Provider
      value={{
        collectionCompletions,
        setCollectionCompleted,
      }}
    >
      {children}
    </collectionCompletionsStatusContext.Provider>
  );
};

type Props = {
  className?: string,
  catalogId?: string,
  isViewMode?: boolean,
  onJourneyLoad?: (course?: any) => void,
  headerRef?: React.Ref<BrandingHeaderRef>,
};

const Details = (props: Props) => {
  const {
    className,
    headerRef,
    onJourneyLoad,
    isViewMode = false,
    catalogId: propsCatalogId,
  } = props;
  const containerRef = React.useRef();
  const onJourneyLoadRef = React.useRef<Function>();
  onJourneyLoadRef.current = onJourneyLoad;
  const { getJourney: fetchJourney } = useJourneyFormUtils();
  const paramsCatalogId = useParams<{ catalogId: string }>().catalogId;
  const [isSelfLoading, setIsSelfLoading] = React.useState(!isViewMode);
  const catalogId = propsCatalogId || paramsCatalogId;
  const currentJourney = useSelector((state) => getJourney(state, catalogId));
  const { isLoading: learningJourneyContextIsLoading } = React.useContext(LearningJourneyContext) || { isLoading: undefined };

  const isLoading = learningJourneyContextIsLoading === undefined
    ? isSelfLoading
    : learningJourneyContextIsLoading;

  React.useEffect(() => {
    if (!isViewMode) {
      setIsSelfLoading(true);
      fetchJourney(catalogId)
        .then((action) => {
          setIsSelfLoading(false);
          onJourneyLoadRef.current?.(action.payload.journey);
        })
        .catch(() => {})
        .finally(() => {
          onJourneyLoadRef.current?.();
        });
    }
  }, [catalogId, isViewMode, fetchJourney]);

  const journeyDetailsContextValue = {
    catalogId,
    isViewMode,
  };

  return (
    <div className={className} ref={containerRef}>
      <journeyDetailsContext.Provider value={journeyDetailsContextValue}>
        <PopoversContainerContext.Provider value={containerRef.current}>
          <OfferingsBrandingHeader
            ref={headerRef}
            value={{
              name: currentJourney?.name ?? '',
              brandColor: currentJourney?.headerColor,
              fontColor: currentJourney?.nameFontColor,
              logo: currentJourney?.logo ? {
                url: currentJourney.logo.url,
                size: currentJourney.logoSize,
              } : null,
              background: currentJourney?.headerBackground ? {
                url: currentJourney.headerBackground,
              } : null,
            }}
          />
          {isLoading ? (
            <div
              css={css`
                ${masterContainerStyles};
                ${limitedWidthContainerStyles};
              `}
            >
              <LoadingPlaceholder />
            </div>
          ) : <JourneyContent />}
        </PopoversContainerContext.Provider>
      </journeyDetailsContext.Provider>
    </div>
  );
};

export default Details;
