import React from 'react';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';

import store from '../../redux/store';
import { updateCourseFromAngular } from '../../redux/actions/courses';
import { unsetAllCachedLecturePages } from '../../redux/actions/lecture-pages';
import AutomatedTranslationSwitch from '../../course_home/components/automated-translation-switch';

/* @ngInject */
export default function LanguageModalController(
  $uibModalInstance,
  CurrentUserManager,
  currentUser,
  CurrentCourseManager,
  context,
  $translate,
  $scope,
  $stateParams,
  AlertMessages,
  $uibModal,
  config,
  ConfirmationOverlays,
  $timeout,
  InstitutionsManager,
) {
  const vm = this;
  let currentCourseId;
  vm.currentUser = currentUser;
  vm.context = context;
  vm.CurrentUserManager = CurrentUserManager;
  vm.InstitutionsManager = InstitutionsManager;
  vm.CurrentCourseManager = CurrentCourseManager;
  vm.saveLanguage = saveLanguage;
  vm.handleDropdownItemClick = handleDropdownItemClick;
  vm.getIsDirty = getIsDirty;
  vm.cancel = cancel;

  vm.availableLanguageKeys = config.platformLanguages;

  vm.formData = {
    currentLanguage: null,
    automatedTranslationEnabled: CurrentCourseManager.course?.automatedTranslationEnabled,
  };

  if (vm.context.isLearner) {
    vm.formData.currentLanguage = vm.currentUser.platformLanguage;
  } else if (vm.context.isCourse) {
    currentCourseId = vm.CurrentCourseManager.course.id;
    vm.formData.currentLanguage = vm.CurrentUserManager.courseIdToUserCourseHash[currentCourseId].course.contentLanguage;
  } else if (vm.context.isJourney) {
    vm.formData.currentLanguage = vm.context.currentLanguage;
  }

  vm.initialFormData = clone(vm.formData);

  function saveLanguage() {
    vm.saving = true;

    if (vm.context.isLearner) {
      const uibModalInstance = $uibModal.open({
        backdropClass: 'modal-overlay-backdrop',
        templateUrl: 'shared/templates/language_loading_overlay.html',
        windowClass: 'modal-overlay',
        keyboard: false,
      });

      vm.CurrentUserManager.updatePlatformLanguage(vm.formData.currentLanguage).then(() => {
        vm.saving = false;
      });

      vm.currentUser.platformLanguage = vm.formData.currentLanguage;

      vm.CurrentUserManager.updateOrigamiLanguage(vm.formData.currentLanguage).then(() => {
        uibModalInstance.close();
        uibModalInstance.rendered.then(() => {
          uibModalInstance.close();
        });
      });
    } else if (vm.context.isCourse) {
      vm.CurrentCourseManager.updateCourseBasics($stateParams.catalogId, {
        contentLanguage: vm.formData.currentLanguage,
        automatedTranslationEnabled: vm.formData.automatedTranslationEnabled,
      }).then((result) => {
        const languageChanged = vm.formData.currentLanguage !== vm.initialFormData.currentLanguage;
        const automatedTranslationChanged = vm.formData.automatedTranslationEnabled !== vm.initialFormData.automatedTranslationEnabled;

        if (automatedTranslationChanged) {
          store.dispatch(unsetAllCachedLecturePages());
        }

        store.dispatch(updateCourseFromAngular({
          catalogId: $stateParams.catalogId,
          contentLanguage: vm.formData.currentLanguage,
          automatedTranslationEnabled: vm.formData.automatedTranslationEnabled,
        }));

        vm.CurrentUserManager.courseIdToUserCourseHash[currentCourseId].course.contentLanguage = vm.formData.currentLanguage;

        vm.saving = false;

        if (languageChanged) {
          AlertMessages.success('FORM.SUCCESS_BANG', 'COURSE_HOME.LANGUAGE.SUCCESS', null, { language: $translate.instant(`LANGUAGE_LABEL.${vm.formData.currentLanguage.toUpperCase()}`) });
        }

        if (automatedTranslationChanged) {
          AlertMessages.success(
            'FORM.SUCCESS_BANG',
            `COURSE_HOME.LANGUAGE.${vm.formData.automatedTranslationEnabled ? 'ENABLE' : 'DISABLE'}_TRANSLATION_SUCCESS`,
            {},
            { courseAlias: CurrentCourseManager.course.getAliases().courseAlias },
          );
        }

        vm.initialFormData = clone(vm.formData);
        $uibModalInstance.close();
      });
    } else if (vm.context.isJourney) {
      vm.saving = true;

      vm.context.updateJourneyLanguage(vm.formData.currentLanguage).then(() => {
        AlertMessages.success(
          'FORM.SUCCESS_BANG',
          'LEARNING_JOURNEYS.DASHBOARD.LANGUAGE_MODAL.SUCCESS',
          null,
          {
            language: $translate.instant(`LANGUAGE_LABEL.${vm.formData.currentLanguage.toUpperCase()}`),
          },
        );
        $timeout(() => {
          vm.saving = false;
          vm.initialFormData = clone(vm.formData);
          $uibModalInstance.close();
        });
      });
    }
  }

  function handleDropdownItemClick(key) {
    vm.formData.currentLanguage = key;
  }

  function getIsDirty() {
    return !isEqual(vm.formData, vm.initialFormData);
  }

  function cancel() {
    vm.formData = clone(vm.initialFormData);
    $uibModalInstance.close();
  }

  $scope.$on('modal.closing', ($event) => {
    if ((vm.context.isCourse || vm.context.isJourney) && vm.getIsDirty() && !vm.dismissedByUser) {
      $event.preventDefault();

      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-inline-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.dismissedByUser = true;
        $uibModalInstance.close();
      });
    }
  });

  const AutomatedTranslationComponent = () => React.createElement(
    AutomatedTranslationSwitch,
    {
      onChange: (newChecked) => $timeout(() => {
        vm.formData.automatedTranslationEnabled = newChecked;
      }),
      initialValue: vm.formData.automatedTranslationEnabled,
    },
  );

  $scope.AutomatedTranslationSwitch = AutomatedTranslationComponent;
}
